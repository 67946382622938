import Carousel from 'react-bootstrap/Carousel';

function SupportCarousel() {
  return (
    <Carousel indicators={false} className='services-slides services-slides-a'>
      
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/23.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default SupportCarousel;