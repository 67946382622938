import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import {FaThumbsUp, FaCheckCircle} from 'react-icons/fa';
import {ImFileText2} from 'react-icons/im'
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {

useEffect(()=>{
document.title = "About Us | Prominence General Supplies Limited";

},[]);


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To be a leading Quality supplier company in providing our clients with innovative solutions
for all the general supplies and requirements to optimize their business objectives.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To provide you with the highest quality products and services possible in a timely manner at
a competitive price. We promise to listen and help to achieve your business goals. We will
always be there with honest, expert advice with quick, friendly customer service.</p>

                    </article>

                    


                 </div>

                 <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                          <li> Honesty</li>
<li>Integrity</li>
<li>Professionalism</li>
<li>Competence</li>
<li>Reliability</li>
<li>Confidentiality</li>
<li>Commitment</li>
<li>Teamwork</li>
                          </ul>
                          
                          

                    </article>

                    {/* <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article> */}


                 </div>
          </div>
    </section>
    <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> */}
     {/* <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2> */}
    {/* <div className='line1'></div> */}
   
    <div className='home-services-container home-services-container-a'>
      {/* <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div> */}
       {/* <div className='home-services-data'> */}
       <div className='home-services-info home-services-info-a'>

        <div className='home-service-box home-service-box-a'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Our Service Culture</h3>
          <p>Our culture is to focus on our client’s interest by providing active
input and open dialogue on issues identified for resolution.</p>

<p>We are committed to principles of sound corporate governance.
We also ensure our services meet the need for transparency both
local and at the international market. We place high value in the
open, clear communication to our clients and in the integrity of
our staff. Our aim is to build a strong working relationships with
our clients for long term mutual success.</p>

<p>We enjoy what we do, Prominence General Supplies Ltd believes
that a constructive working environment is essential for our staff
and quality of services.</p>

        </div>
       
        <div className='home-service-box home-service-box-a'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><ImFileText2></ImFileText2></span>
          </div>
          <h3 className='home-service-title'>Corporate Policy</h3>
          <p>Our corporate policy is to co-prosper with our clients. We benefit
our customers as we strive to achieve corporate growth.
</p>
<p>We are eager to understand the client’s needs. We provide
services and develop innovative products from the client’s needs
view point. We confirm that our employees act in full compliance
with all laws and regulations. We endeavor to strengthen our
corporate social responsibility policy.</p>
<p>We take our corporate responsibility seriously across the group
as an employer, investor and a consumer. We are committed to
understanding, monitoring and managing our social,
environmental and economic impact to enable us contribute to
society’s wider goal of sustainable development. We aim to
demonstrate these responsibilities through our actions and
within our corporate policies.
</p>

        </div>
        <div className='home-service-box home-service-box-a'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheckCircle></FaCheckCircle></span>
          </div>
          <h3 className='home-service-title'>Quality Control</h3>
          <p>Our Quality Control Manager oversees the quality of our major
work sites, and is the first point of contact with our clients to
ensure good communication flow and service delivery.
Inspections are carried out regularly to ensure high standards.</p>

<p>We strive to hunt down and eradicate any problems before they
become a concern to you.
We ensure our suppliers practice good business practices
through our vetting process.
We ensure all our supplies meet the industry standards and are
made while adhering to the set laws and regulations.</p>
        </div>
{/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div> */}

 {/* <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div> */}

    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        {/* </div> */}


    </div>



  </section>

    </>
  )
}

export default WhoWeAre