import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Services | Prominence General Supplies Limited";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of Assorted Office Stationery</h3>
                     <div className='line1'></div>
                     <p>
    We supply well customised office
stationery equipments like Reams of
paper, Box files, Pens, Calculators,
Notebooks, Diaries, Desk organisers,
etc.
                   
                     </p>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of Cleaning & Sanitary Materials</h3>
                     <div className='line1'></div>
                     <p>
                We provide cleaning materials to offices, schools, hospitals, tertiary institutions, homes, warehouses,
government & non-government
institutions, etc.
                     
                     </p>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of Perishable & Non-perishable Foods</h3>
                       <div className='line1'></div>
                       <p>
              We supply perishable goods like Meat,
Fish, Milk, Eggs, Chicken, Vegetables,
Fruits, etc. We also supply well customized, dry food
like Beans, Maize Flour, Rice, Groundnuts,
Simsim, Soya beans, etc.
                     
                     </p>
                     {/* <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of Educational Materials</h3>
                    <div className='line1'></div>
                    <p>These include agricultural seeds, laboratory reagents, laboratory equipment, clothing & textile materials, etc.</p>
                   
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>
          
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Printing & Branding</h3>
                       <div className='line1'></div>
                       <p>
            Here our products and services include; Annual Reports, Black & White Copies, Branded Pens
Courier Services (door-to-door)
Bags, Booklets, Branded Clothing, Brochures,
Business Cards, Calendars, Caps, Carbonless Books
Catalogues, Corporate Gifts, Coupons, Envelopes,
Flyers, Post Cards, Invitations, Labels, Letterheads,
Menus, Newsletters, Notepads, Personal Stationery,
Posters, Price Lists, Pull Up Banners, Raffle Tickets /
Booklets, Rubber Stamps, Stationery, Graphic Design,
T-shirts, etc.
                     
                     </p>
                     {/* <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
            
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>General Supplies</h3>
                    <div className='line1'></div>
                    <p>Suppy of Farming Tools and Equipment. We supply all kinds of gardening tools. These include;
Hoes, Spades, Rakes, Saws, Buckets, Pick axe, Wheel
barrows, Sugar bud chipper, Ploughs, Land levelers,
Bale spears, Shovels, Pangas, Sprinklers, Seeder and
Fertilizers and many more. All our tools meet the standards set in Uganda. Our
tools add value to your farm through boosting
productivity </p>
                   
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Bid Preparation Services</h3>
                       <div className='line1'></div>
                       <p>
            
                     
                     </p>
                     {/* <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>
 </div>
    </section>
  )
}

export default Services