import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaHandshake, FaComments, FaFileAlt,FaCheckCircle} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Prominence General Supplies Limited | My Customer, My Responsibility";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a'>
          <div className='circle1'>
          </div>
          <div className='circle2'>
          </div>
          <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Prominence General Supplies Limited</strong> is a private company incorporated under the Companies Act of the laws of the Republic of Uganda. Our primary mandate is to procure and deliver supplies to the Government of Uganda, non-governmental organizations, places of worship, learning institutions, businesses, financial institutions and private individuals. We are also prominent in bid preparation services. </p>
<p className='home-p'>In the current ever changing and turbulent world, it is
critical that companies conduct themselves in a confident,
disciplined, diplomatic and professional manner. It is vitally
important to develop relationships with governments,
government agencies, Non- government Organizations,
international Companies and national companies that are
fair to all, strong enough and adaptable enough to
withstand the test of time.</p>
<p className='home-p'>Critical to the success of our business venture is that we
provide appropriate goods and services in a safe
environment for the business functions to continue flourishing. Prominence General Supplies Limited provides
the key to that safe and secure business environment
through providing customized products and services</p>


 
        </div>

    </section>
  <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 128, 0.706),rgba(0, 0, 0, 0.806)), url("./images/2.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'5rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Objectives</h2>
            <div className='pledge-line'></div>
         
         <p>
          <span className='quote-cover'><FaCheckCircle></FaCheckCircle></span> 
 To meet client’s objectives through creativity, invention and service orientation.
</p>
 <p>
  <span className='quote-cover'><FaCheckCircle></FaCheckCircle></span> 
 To raise the standards of the organization in the country and beyond through providing
high quality products and services in an effective and efficient way.
</p>

        </div>
    </section>
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     {/* <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2> */}
    <div className='line1'></div>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div> */}
       {/* <div className='home-services-data'> */}
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Sustainability</h3>
          <p>We consider our clients as our partners and we work together for greater sustainability across each project. Our systems and checklists allow close monitoring of the process from quality control, clean products equipment and real time delivery of products or services.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Experienced</h3>
          <p>We continue to invest in training our people to ensure that we drive improvements in our product and service delivery. This has resulted into an efficient and effective team of professionals who possess the right skills and service knowledge to respond to each unique situation that may arise. </p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaComments></FaComments></span>
          </div>
          <h3 className='home-service-title'>Communication</h3>
          <p>Our partnership approach creates an open line of communication between our clients and our team. We strongly believe in face to face approach to client service management, however, we utilize technology to ensure efficient communication between our clients and our teams. </p>
        </div>
{/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div> */}

 {/* <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div> */}

    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        {/* </div> */}


    </div>



  </section>
   
    
  
    </>
  )
}

export default Home