import React from 'react'

let ValuesData = [

{title: "Integrity",      
content: "We are accountable to our customers, suppliers and other stakeholders."
},

{title: "Teamwork",      
content: "We are explicit in all our dealings and take pride in the work we do and work as a team."
},
{title: "Professionalism",      
content: "No compromise to our standards, committed to whatever decisions we take and accept the consequences."
},
{title: "Best Prices",      
content: "We offer the best brands at affordable market rates accompanied with great customer service."
}
];

export default ValuesData;